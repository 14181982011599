/* Base Styles (Desktop) - Keep your original desktop styles */
.statistics {
  padding-top: 30px;
  padding-bottom: 120px;
  padding-left: 80px;
  padding-right: 80px;
  background-color: #fff;
}

.statistics-card {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 40px;
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 4px 4px 20px 0px #0000001f;
}

.statistics-card__item {
  text-align: center;
  flex: 1;
}

.statistics-card__number {
  font-size: 86px;
  color: #4285f3;
  margin: 0;

  font-weight: 700;
}

.statistics-card__label {
  font-size: 18px;
  color: #000000;
  margin: 0.5rem 0 0;

  font-weight: 600;
}

.statistics-card__item:not(:last-child) {
  border-right: 1px solid #e0e0e0;
  padding-right: 2rem;
}

.statistics-card__item:last-child {
  padding-left: 2rem;
}

/* Mobile Styles */
@media (max-width: 768px) {
  .statistics {
    
    padding-top: 20px;
    padding-bottom: 60px;
    margin: 40px;
  }

  .statistics-card {
    padding: 20px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
  }

  .statistics-card__item {
    padding: 20px 0;
    border: none;
  }

  .statistics-card__number {
    font-size: 56px;
  }

  .statistics-card__label {
    font-size: 14px;
  }
}

/* Extra Small Screens */
@media (max-width: 480px) {
  .statistics {
    padding-top: 1px;
    padding-bottom: 40px;
    padding-left: 1px;
    padding-right: 1px;
    /* width: 100%; */
  }

  .statistics-card {
    padding: 10px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
  }
  .statistics-card__item {
    text-align: center; /* Center-align text on extra small screens */
  }
  .statistics-card__number {
    font-size: 35px;
  }

  .statistics-card__label {
    font-size: 12px;
  }

  .statistics-card__item:not(:last-child) {
    border-right: none;
  }
}
