/* .about-product {
  display: flex;
  align-items: center;
  background-color: #E4F0FC;
  padding: 60px;
 

}

.about-product__image-container {
  flex-shrink: 0;
  margin-right: 80px;
}

.about-product__image {
  width: 514.09px;
  height: 504.66px;
  margin-left: 69px;
}

.about-product__content {
  flex-grow: 1;
}

.about-product__title {

  font-size: 48px; 
  font-weight: 700; 
  line-height: 51.84px; 
  text-align: left; 
  color: #000000; 
  margin-bottom: 50px; 
}


.about-product__list {
  list-style: none;
  padding: 0;
  margin: 0;
  margin-bottom: 80px;
}


.about-product__list-item {
 
  font-size: 24px; 
  font-weight: 400; 
  line-height: 31.2px;
  text-align: left; 
  color: #142349; 
   margin-bottom: 30px;
}


.about-product__bullet {
  width: 16px;
  height: 16px;
  background-color: #4a90e2;
  border-radius: 50%;
  display: inline-block;
  margin-right: 10px;
} */
/* General Styles - Desktop and Larger Screens */
.about-product {
  display: flex;
  align-items: center;
  background-color: #e4f0fc;
  padding: 60px;
}

.about-product__image-container {
  flex-shrink: 0;
  margin-right: 80px;
}

.about-product__image {
  width: 514.09px;
  height: 504.66px;
  margin-left: 69px;
}

.about-product__content {
  flex-grow: 1;
}

.about-product__title {

  font-size: 48px;
  font-weight: 700;
  line-height: 51.84px;
  text-align: left;
  color: #000000;
  margin-bottom: 50px;
}

.about-product__list {
  list-style: none;
  padding: 0;
  margin: 0;
  margin-bottom: 80px;
}

.about-product__list-item {

  font-size: 24px;
  font-weight: 400;
  line-height: 31.2px;
  text-align: justify;
  color: #142349;
  margin-bottom: 30px;
}

.about-product__bullet {
  width: 16px;
  height: 16px;
  display: inline-block;
  margin-right: 10px;
  background-image: url("../Images/tick.svg"); /* Replace with your image path */
  background-size: cover;
  background-position: center;
  top: 5.11px; /* Adjust if needed */
  gap: 0px; /* Remove this if not necessary */
  opacity: 1; /* Ensure visibility */
}
/* Responsive Styles - Tablet Screens */
@media (max-width: 1024px) {
  .about-product {
    flex-direction: column;
    padding: 40px;
  }

  .about-product__image-container {
    margin-right: 0;
    margin-bottom: 40px;
    text-align: center;
  }

  .about-product__image {
    width: 100%;
    height: auto;
    margin-left: 0;
  }

  .about-product__title {
    font-size: 36px;
    line-height: 40px;
    text-align: center;
    margin-bottom: 40px;
  }

  .about-product__list-item {
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 20px;
  }
}

/* Mobile Styles - Small Screens */
@media (max-width: 768px) {
  .about-product {
    padding: 30px;
  }

  .about-product__title {
    font-size: 28px;
    line-height: 32px;
    text-align: center;
  }

  .about-product__list-item {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 15px;
  }
}

/* Mobile Styles - Extra Small Screens */
@media (max-width: 480px) {
  .about-product {
    padding: 20px;
  }

  .about-product__image-container {
    margin-bottom: 30px;
  }

  .about-product__title {
    font-size: 24px;
    line-height: 28px;
  }

  .about-product__list-item {
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 10px;
  }
}
