/* NAACTable.css */
.naac-table-container {
  padding: 2rem;
  background-color: #f9f9f9;
}

h3 {
  text-align: left;
  margin-bottom: 1rem;
}

.naac-table {
  width: 100%;
  border-collapse: collapse;
  background-color: #ffffff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.naac-table th,
.naac-table td {
  padding: 0.75rem;
  text-align: left;
  border: 1px solid #e0e0e0;
}

.naac-table th {
  background-color: #f7f9fc;
  font-weight: bold;
}

.naac-table__footer {
  background-color: #f7f9fc;
}

.naac-table__footer td {
  font-weight: bold;
}
