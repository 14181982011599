.App {
  text-align: center;
  background-color: white;
  width: 100%;
  overflow-x: hidden;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

:where(.css-dev-only-do-not-override-qnu6hi).ant-float-btn-default .ant-float-btn-body {
  background-color: #61fd7d;
  /* Change to your desired color */
  /* You can uncomment and modify the transition if needed */
  /* transition: background-color 0.2s; */
}

/* WhatsApp floating button */
.whatsapp_float {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
  animation: shake 2s infinite;
  /* Add shake animation */
  background-color: #2bb826;
}

/* WhatsApp icon image */
.whatsapp_float img {
  width: 100%;
  height: 100%;
}

/* Define the keyframes for shaking */
@keyframes shake {

  0%,
  100% {
    transform: translateX(0);
  }

  25% {
    transform: translateX(-2px);
  }

  50% {
    transform: translateX(2px);
  }

  75% {
    transform: translateX(-2px);
  }
}

/* Hover effect - slight scale up */
.whatsapp_float:hover {
  transform: scale(1.1);
}

.navbar {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  background-color: #fff;
  /* Adjust based on your design */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  /* Optional: Adds a subtle shadow */
}

.content {
  padding-top: 60px;
  /* Adjust based on your Navbar's height */
}