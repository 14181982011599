.benefits {
  text-align: center;
  /* padding: 40px 20px; */
  padding-left: 80px;
  padding-right: 80px;
  background-color: #f9f9f9;
  padding-bottom: 100px;
  padding-top: 100px;
}

.benefits__title {
  font-size: 48px;
  line-height: 51.84px;
  color: #4285F3;

  font-weight: 700;

  margin-bottom: 40px;
}

.benefits__cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 50px;
}

.benefits__card {
  background-color: #fff;
  border-radius: 8px;
  padding: 20px;
  width: 350px;
  box-shadow: 4px 8px 20px 0px #0000001A;
  text-align: center;
}

.benefits__icon {
  width: 60px;
  height: 60px;
  color: #ff6600;
}

.benefits__card-title {
  font-size: 24px;
  line-height: 25.92px;
  font-weight: 700;
  color: #000000;
  margin-bottom: 10px;
  text-align: center;

}

.benefits__description {
  font-size: 16px;
  text-align: center;
  line-height: 25.6px;
  font-weight: 400;

  color: #000000;
  margin: 0;
}
