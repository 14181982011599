

/* General Styles - Desktop and Larger Screens */
.benefits-section {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 40px 50px;
  background-color: white;
  padding-top: 100px;
}

.benefits-section__content {
  display: flex;
  width: 100%;
  align-items: center;
}

.benefits-section__image-container {
  flex: 1;
  display: flex;
  align-items: center;
}

.benefits-section__image {
  max-width: 100%;
  height: auto;
}

.benefits-section__text-content {
  flex: 1;
  padding-left: 2rem;
}

.benefits-section__brand {
  color: #ff9140;

  font-weight: 700;
  font-size: 19px;
  line-height: 24.74px;
}

.benefits-section__heading {
  font-size: 2.5rem;
  line-height: 62.5px;
  color: #142349;
  margin-bottom: 1rem;
  margin: 0;

  font-weight: 600;
  text-align: left;
}

.benefits-section__list {
  list-style: none;
  padding: 0;
}

.benefits-section__list-item {
  display: flex;
  align-items: center;
  font-size: 1.125rem;
  color: #142349;
  margin-bottom: 1rem;

  line-height: 1.6;
  text-align: justify;
  font-weight: 500;
}

.benefits-section__list-icon {
  width: 16px; /* Adjust the size as needed */
  height: 16px; /* Adjust the size as needed */
  display: inline-block;
  background-image: url("../Images/tick.svg"); /* Replace with your image path */
  background-size: cover;
  background-position: center;
  margin-right: 10px; /* Spacing between the icon and the text */
  vertical-align: middle; /* Align the icon vertically with the text */
  content: ""; /* Remove the text checkmark */
}

.highlight {
  color: #ff9140;
}

/* Responsive Styles - Tablet Screens */
@media (max-width: 1024px) {
  .benefits-section {
    padding: 10px 40px 50px;
    padding-top: 80px;
  }

  .benefits-section__content {
    flex-direction: column;
    text-align: center;
  }

  .benefits-section__text-content {
    padding-left: 0;
    padding-top: 2rem;
  }

  .benefits-section__heading {
    font-size: 2rem;
    line-height: 50px;
    text-align: center;
  }

  .benefits-section__list-item {
    
    text-align: justify;
  }
}

/* Mobile Styles - Small Screens */
@media (max-width: 768px) {
  .benefits-section {
    padding: 10px 20px 40px;
    padding-top: 60px;
  }

  .benefits-section__heading {
    font-size: 1.75rem;
    line-height: 40px;
  }

  .benefits-section__list-item {
    font-size: 1rem;
    line-height: 1.4;
  }

  .benefits-section__list-icon {
    font-size: 1rem;
  }
}

/* Mobile Styles - Extra Small Screens */
@media (max-width: 480px) {
  .benefits-section {
    padding: 0px 30px 0px;
   
  }

  .benefits-section__heading {
    font-size: 1.5rem;
    line-height: 36px;
    text-align: justify;
  }

  .benefits-section__list-item {
    font-size: 0.875rem;
  }

  .benefits-section__list-icon {
    font-size: 0.875rem;
  }
}
