/* Main section container */
.empower {
  text-align: center;

  margin-top: 80px;
  padding: 60px 60px;
  background-color: #e4f0fc;
}

/* Title styling */
.empower__title {
  font-size: 2.5rem;
  color: #4285f3;
  margin-bottom: 60px;
  font-weight: 700;
  letter-spacing: 1.1px;
  margin-top: 80px;
}

/* Grid container */
.empower__grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* 3 columns for desktop */
  gap: 20px;
  margin: 0 auto;
}

/* Individual card */
.empower__card {
  background-color: #fff;
  border-radius: 16px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  padding: 20px;
  transition: transform 0.3s ease-in-out;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

/* Hover effect on card */
.empower__card:hover {
  transform: scale(1.05);
}

/* Card image */
.empower__image {
  width: 100%;
  height: auto;
  border-radius: 8px;
  margin-bottom: 1px;
  transition: transform 0.3s ease-in-out; /* Smooth transition for the zoom effect */
}

/* Zoom in effect on hover */
.empower__image:hover {
  transform: scale(1.03); /* Slight zoom-in effect */
}

/* Special case for card with image on bottom */
.empower__card img:last-of-type {
  margin-top: 5px; /* Ensures the image has space at the bottom */
}

/* Card title */
.empower__card-title {
  font-size: 1.25rem;
  color: #000000;
  font-weight: 700;
  margin-bottom: 1px;
  text-align: left;
}

/* Description */
.empower__description {
  font-size: 1rem;
  color: #000000;
  margin-bottom: 10px;
  text-align: left;
  font-weight: 400;
  margin-top: 10px;
}

/* Special case for card with image on bottom */
.empower__card img:last-of-type {
  margin-top: 5px; /* Ensures the image has space at the bottom */
}

/* Responsive styling */

/* For screens smaller than 1024px (e.g., tablets) */
@media (max-width: 1024px) {
  .empower__grid {
    grid-template-columns: repeat(2, 1fr); /* 2 columns for tablet */
  }
}

/* For screens smaller than 768px (e.g., mobile) */
@media (max-width: 768px) {
  .empower__grid {
    grid-template-columns: 1fr; /* 1 column for mobile */
  }

  .empower {
    padding: 30px 20px; /* Adjust padding for smaller screens */
  }
}
