/* General Styles - Desktop and Larger Screens */
.ctasection {
  justify-content: center;
  display: flex;
}

.cta {
  background-color: #4285f3;
  padding: 40px 180px;
  border-radius: 20px;
  
  justify-content: center;
  align-items: center;

  
  margin-top: 70px;
  margin-bottom: 100px;
 
}

.cta__content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 900px;
}

.cta__text {
  color: white;
  font-size: 36px;
  font-weight: 700;
  margin-right: 20px;
  text-align: left;
}

.cta__buttons {
  display: flex;
  gap: 24px;
}

.cta__button {
  padding: 20px 20px;
  border-radius: 47px;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  min-width: 180px;
  text-align: center;
}

.cta__button--primary {
  background-color: white;
  color: #4285f4;
  border: none;
}

.cta__button--secondary {
  background-color: transparent;
  color: white;
  border: 2px solid white;
}

.cta__button--primary:hover {
  background-color: #e3e3e3;
}

.cta__button--secondary:hover {
  background-color: white;
  color: #4285f4;
}

/* Responsive Styles - Tablet Screens */
@media (max-width: 1024px) {
  .cta {
    padding: 30px 30px;
    margin-left: 5%;
    margin-right: 5%;
    margin-top: 50px;
    margin-bottom: 80px;
  }

  .cta__content {
    flex-direction: column;
    text-align: center;
    gap: 20px;
  }

  .cta__text {
    font-size: 30px;
    margin-right: 0;
    text-align: center;
  }

  .cta__buttons {
    justify-content: center;
    gap: 16px;
  }
}

.modal {
  background: #fff;
  padding: 40px;
  max-width: 1200px;
  width: 500px;
  margin: auto;
  border-radius: 10px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  text-align: left;
  /* Align text to the left */
}

.modal h2 {
  margin-bottom: 20px;
  font-size: 24px;
  text-align: center;
  /* Center the title */
}

.modal-form {
  display: flex;
  flex-direction: column;
  /* Stack form fields vertically */
  gap: 20px;
  /* Space between fields */
}

.form-group {
  display: flex;
  flex-direction: column;
  /* Stack label and input vertically */
}

.modal-form label {
  font-weight: bold;
  /* Bold labels */
  margin-bottom: 5px;
  /* Space between label and input */
}

.modal-form input {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
}

.button-group {
  display: flex;
  justify-content: space-between;
  /* Space out buttons */
}

.modal-form button {
  padding: 10px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  width: 48%;
  /* Make buttons take up equal space */
}

.modal-form button[type="submit"] {
  background-color: #007bff;
  /* Primary color */
  color: white;
}

.modal-form button[type="button"] {
  background-color: #f1f1f1;
  /* Secondary color */
  color: #333;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Mobile Styles - Small Screens */
@media (max-width: 768px) {
  .cta {
    padding: 20px 20px;
    margin-left: 3%;
    margin-right: 3%;
    margin-top: 40px;
    margin-bottom: 60px;
  }

  .cta__text {
    font-size: 24px;
  }

  .cta__button {
    padding: 15px 15px;
    font-size: 0.9rem;
    min-width: 150px;
  }
}

/* Mobile Styles - Extra Small Screens */
@media (max-width: 480px) {
  .cta {
    padding: 15px 15px;
    margin-left: 2%;
    margin-right: 2%;
    margin-top: 30px;
    margin-bottom: 50px;
  }

  .cta__text {
    font-size: 20px;
  }

  .cta__button {
    padding: 12px 12px;
    font-size: 0.8rem;
    min-width: 130px;
  }

  .cta__buttons {
    flex-direction: column;
    gap: 12px;
  }
}