/* General Styles - Desktop and Larger Screens */
.available-courses {
  text-align: center;
  padding: 10px 80px 50px;
  padding-top: 50px;
  background-color: #e4f0fc;
}

.available-courses__title {
  font-size: 2.5rem;
  color: #4285f3;
  margin-bottom: 40px;
  font-weight: 700;
  letter-spacing: 1.1px;
  margin-top: 10px;
}

.available-courses__grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 30px;
  justify-items: center;
}

.available-courses__card {
  background-color: #fff;
  border-radius: 16px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  padding: 20px;
  max-width: 300px;
  text-align: left;
  transition: transform 0.3s ease-in-out;
  border: 2px solid #ffffff;
}

.available-courses__card:hover {
  transform: scale(1.05);
}

.available-courses__image {
  border-radius: 8px;
  width: 100%;
  height: auto;
  transition: transform 0.3s ease-in-out;
  /* Smooth transition for the zoom effect */
}

/* Zoom in effect on hover */
.available-courses__image:hover {
  transform: scale(1.05);
  /* Slight zoom-in effect */
}

.available-courses__card-title {
  font-size: 1.25rem;
  color: #000000;
  font-weight: 700;
  margin-bottom: 0.5rem;
}

.available-courses__rating {
  font-size: 1rem;
  color: #ffbf00;
  margin-bottom: 0.5rem;
}

.available-courses__description {
  font-size: 0.9rem;
  color: #000000;
  font-weight: 400;
  margin-bottom: 0.5rem;
}

.available-courses__price {
  font-size: 1rem;
  color: #ff4d4f;
  font-weight: bold;
}

/* Responsive Styles - Tablet Screens */
@media (max-width: 1024px) {
  .available-courses {
    padding: 10px 40px 50px;
    margin-top: 50px;
  }

  .available-courses__title {
    font-size: 2rem;
    margin-top: 100px;
    margin-bottom: 30px;
  }

  .available-courses__grid {
    gap: 20px;
  }

  .available-courses__card {
    max-width: 100%;
    padding: 15px;
  }

  .available-courses__card-title {
    font-size: 1.15rem;
  }

  .available-courses__description {
    font-size: 0.875rem;
  }
}

/* Mobile Styles - Small Screens */
@media (max-width: 768px) {
  .available-courses {
    padding: 10px 20px 40px;
    margin-top: 40px;
  }

  .available-courses__title {
    font-size: 1.75rem;
    margin-top: 80px;
    margin-bottom: 20px;
  }

  .available-courses__grid {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 15px;
  }

  .available-courses__card {
    padding: 10px;
  }

  .available-courses__card-title {
    font-size: 1rem;
  }

  .available-courses__description {
    font-size: 0.85rem;
  }

  .available-courses__price {
    font-size: 0.9rem;
  }
}

/* Mobile Styles - Extra Small Screens */
@media (max-width: 480px) {
  .available-courses {
    padding: 40px 80px 40px;
    margin-top: 30px;
  }

  .available-courses__title {
    font-size: 1.5rem;
    margin-top: 60px;
    margin-bottom: 20px;
  }

  .available-courses__grid {
    gap: 10px;
  }

  .available-courses__card {
    padding: 8px;
    margin-bottom: 20px;
  }

  .available-courses__card-title {
    font-size: 0.9rem;
  }

  .available-courses__description {
    font-size: 0.8rem;
  }

  .available-courses__price {
    font-size: 0.85rem;
  }
}