/* .explore {
  position: relative;
  width: 100%;
  height: 100%;
}

.explore__image {
  width: 100%;
  height: auto;
  filter: brightness(1.2); 
}

.explore__overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: white;
  padding: 20px;
  width: 80%;
}

.explore__title {
    font-size: 2.5rem;
    color: white;
    font-weight: 700;
    letter-spacing: 1.2px;
    font-family: 'DM Sans', sans-serif;
    line-height: 1.2;
    text-transform: uppercase; 
  } */

/* General Styles - Desktop and Larger Screens */
.explore {
  position: relative;
  width: 100%;
  height: 100%;
}

.explore__image {
  width: 100%;
  height: auto;
  filter: brightness(1.2);
}

.explore__overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: white;
  padding: 20px;
  width: 80%;
}

.explore__title {
  font-size: 2.5rem;
  color: white;
  font-weight: 700;
  letter-spacing: 1.2px;

  line-height: 1.2;
  text-transform: uppercase;
}

/* Responsive Styles - Tablet Screens */
@media (max-width: 1024px) {
  .explore__overlay {
    width: 90%;
    padding: 15px;
  }

  .explore__title {
    font-size: 2rem;
    letter-spacing: 1px;
  }
}

/* Mobile Styles - Small Screens */
@media (max-width: 768px) {
  .explore__overlay {
    width: 95%;
    padding: 10px;
  }

  .explore__title {
    font-size: 1.75rem;
    letter-spacing: 0.8px;
  }
}

/* Mobile Styles - Extra Small Screens */
@media (max-width: 480px) {
  .explore__overlay {
    width: 100%;
    padding: 8px;
  }

  .explore__title {
    font-size: 1.5rem;
    letter-spacing: 0.7px;
  }
}
