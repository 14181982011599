.placement-training {
  display: flex;
  align-items: center;
  justify-content:space-between;
  height: 462px;
  background-color: #4285F3;
  border-radius: 12px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
  margin-left: 80px;
  margin-right: 80px;
  margin-top: 200px;
  padding-left: 50px;
  padding-right: 50px;
}

.placement-training__content {
  color: #ffffff;
  margin-right: 30px;

  font-weight: 700;
}

.placement-training__title {
  font-size: 48px;
  line-height: 51.84px;
  font-weight: bold;
  margin-bottom: 20px;
}

.placement-training__list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.placement-training__item {
  font-size: 1rem;
  margin-bottom: 10px;
  position: relative;
  padding-left: 20px;
  text-align: left;

  font-weight: 600;

}

.placement-training__item::before {
  content: "•";
  position: absolute;
  left: 0;
  color: #ffffff;
  font-size: 20px;
  text-align: left;

  font-weight: 600;

}

.placement-training__image-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.placement-training__image {
  width: 545px; /* Adjust as needed */
  position: relative;
  height: 590px;
  top: -67px;
}
