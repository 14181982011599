/* Base Styles (Desktop) */
.codexodus {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 80px;
  background-color: white;
  padding-top: 150px;
}

.codexodus__content {
  max-width: 45%;
  text-align: justify;
  font-size: 20px;
}

.codexodus__title {
  font-size: 40px;
  line-height: 43.2px;
  color: #ff9140;

  font-weight: 700;
  margin: 0;
  margin-bottom: 20px;
}

.codexodus__title--highlight {
  color: #ff9140;
}

.codexodus__brand {
  font-size: 86px;
  line-height: 92.88px;
  color: #000;
  font-weight: 700;

  margin: 0;
  margin-bottom: 15px;
}

.codexodus__description {
  font-size: 1.2rem;
  color: #000;
  margin-bottom: 1.5rem;

  font-weight: 400;
  line-height: 38.4px;
}

.codexodus__buttons {
  display: flex;
  gap: 1rem;
}

.codexodus__button {
  padding: 0.75rem 1.5rem;
  border-radius: 4px;
  border: none;
  font-size: 1rem;
  cursor: pointer;
}

.codexodus__button--primary {
  background-color: #4285f3;
  color: #fff;

  font-weight: 700;
}

.codexodus__button--secondary {
  background-color: transparent;
  color: #4285f3;
  border: 1px solid #4285f3;
}

.codexodus__image-container {
  max-width: 50%;
}

.codexodus__character-image {
  width: 100%;
  height: auto;
  object-fit: contain;
}

.modal {
  background: #fff;
  padding: 40px;
  max-width: 1200px;
  width: 500px;
  margin: auto;
  border-radius: 10px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  text-align: left;
  /* Align text to the left */
}

.modal h2 {
  margin-bottom: 20px;
  font-size: 24px;
  text-align: center;
  /* Center the title */
}

.modal-form {
  display: flex;
  flex-direction: column;
  /* Stack form fields vertically */
  gap: 20px;
  /* Space between fields */
}

.form-group {
  display: flex;
  flex-direction: column;
  /* Stack label and input vertically */
}

.modal-form label {
  font-weight: bold;
  /* Bold labels */
  margin-bottom: 5px;
  /* Space between label and input */
}

.modal-form input {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
}

.button-group {
  display: flex;
  justify-content: space-between;
  /* Space out buttons */
}

.modal-form button {
  padding: 10px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  width: 48%;
  /* Make buttons take up equal space */
}

.modal-form button[type="submit"] {
  background-color: #007bff;
  /* Primary color */
  color: white;
}

.modal-form button[type="button"] {
  background-color: #f1f1f1;
  /* Secondary color */
  color: #333;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Responsive Styles - Tablet Screens */
@media (max-width: 1024px) {
  .codexodus {
    flex-direction: column;
    padding: 60px;
    padding-top: 150px;
  }

  .codexodus__content,
  .codexodus__image-container {
    max-width: 100%;
    text-align: center;
  }

  .codexodus__title {
    font-size: 32px;
    line-height: 35px;
  }

  .codexodus__brand {
    font-size: 72px;
    line-height: 80px;
  }

  .codexodus__description {
    font-size: 1.1rem;
    line-height: 30px;
  }

  .codexodus__buttons {
    justify-content: center;
    margin-top: 20px;
  }

  .codexodus__character-image {
    margin-top: 30px;
  }
}

/* Mobile Styles - Small Screens */
@media (max-width: 768px) {
  .codexodus {
    padding: 40px;
    padding-top: 100px;
    flex-direction: column;
    align-items: center;
  }

  .codexodus__content {
    max-width: 600px;
    text-align: justify;
  }

  .codexodus__title {
    font-size: 28px;
    line-height: 30px;
    text-align: center;
  }

  .codexodus__brand {
    font-size: 48px;
    line-height: 52px;
    text-align: center;
  }

  .codexodus__description {
    font-size: 1rem;
    line-height: 24px;
    text-align: center;
  }

  .codexodus__buttons {
    justify-content: center;
    gap: 1rem;
    margin-bottom: 20px;
  }

  .codexodus__button {
    width: 100%;
    text-align: center;
  }

  .codexodus__image-container {
    width: 100%;
    text-align: center;
    margin-top: 30px;
  }

  .codexodus__character-image {
    max-width: 100%;
    height: auto;
    margin: 0 auto;
  }
}

/* Mobile Styles - Extra Small Screens */
@media (max-width: 480px) {
  .codexodus {

    padding-top: 100px;
    text-align: justify;
    /* Align text left in codexodus */

  }

  .codexodus__title {
    font-size: 22px;
    line-height: 26px;
    text-align: justify;
    /* Align text left for title */
  }

  .codexodus__brand {
    font-size: 36px;
    line-height: 40px;
    text-align: justify;
    /* Align text left for brand */
  }

  .codexodus__description {
    font-size: 0.9rem;
    line-height: 22px;
    text-align: justify;
    /* Align text left for description */
  }

  .codexodus__buttons {
    display: flex;
    justify-content: center;
    gap: 1rem;
    margin-bottom: 20px;
  }

  .codexodus__character-image {
    width: 80%;
  }
}