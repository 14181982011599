/* .certificate {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 100px 110px 0px;
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.1);

  text-align: left;
  padding-top: 130px;
  padding-bottom: 100px;
}

.certificate__content {
  max-width: 50%;
}

.certificate__pre-title {
  color: #ff9140;
  font-size: 19px;
  font-weight: 700;
  margin-bottom: 8px;
  line-height: 24.74px;
}

.certificate__title {
  font-size: 48px;
  color: #142349;
  font-weight: 600;
  margin-bottom: 24px;
  line-height: 62.5px;
}

.certificate__benefits {
  list-style: none;
  padding: 0;
  margin: 0;
}

.certificate__benefit-item {
  display: flex;
  align-items: flex-start;
  margin-bottom: 20px;
}

.certificate__icon {
  font-family: DM Sans;
  width: 54px;
  height: 54px;
  margin-right: 20px;
}

.icon-skill {
  background-image: url("../Images/Employee\ Skills.svg");
  background-size: cover;
}

.icon-rocket {
  background-image: url("../Images/Boost.svg");
  background-size: cover;
}

.icon-certificate {
  background-image: url("../Images/Certificate.svg");
  background-size: cover;
}

.certificate__benefit-title {
  font-size: 20px;
  color: #142349;
  font-weight: 600;
  margin-top: 1px;
}

.certificate__benefit-description {
  font-size: 18px;
  color: #142349;
  font-weight: 500;
  margin-top: -12px;

}

.certificate__image-wrapper {
  max-width: 50%;
  text-align: right;
}

.certificate__image {
  max-width: 100%;
  height: auto;
  border-radius: 12px;
} */

/* General Styles - Desktop and Larger Screens */
.certificate {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 130px 110px 100px;
  background-color: #fff;
 
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.1);

  text-align: left;
}

.certificate__content {
  max-width: 50%;
}

.certificate__pre-title {
  color: #ff9140;
  font-size: 19px;
  font-weight: 700;
  margin-bottom: 8px;
  line-height: 24.74px;
}

.certificate__title {
  font-size: 48px;
  color: #142349;
  font-weight: 600;
  margin-bottom: 24px;
  line-height: 62.5px;
}

.certificate__benefits {
  list-style: none;
  padding: 0;
  margin: 0;
}

.certificate__benefit-item {
  display: flex;
  align-items: flex-start;
  margin-bottom: 20px;
}

.certificate__icon {
  width: 54px;
  height: 54px;
  margin-right: 20px;
  background-size: cover;
}

.icon-skill {
  background-image: url("../Images/Employee\ Skills.svg");
}

.icon-rocket {
  background-image: url("../Images/Boost.svg");
}

.icon-certificate {
  background-image: url("../Images/Certificate.svg");
}

.certificate__benefit-title {
  font-size: 20px;
  color: #142349;
  font-weight: 600;
  margin-top: 1px;
}

.certificate__benefit-description {
  font-size: 18px;
  color: #142349;
  font-weight: 500;
  margin-top: -12px;
}

.certificate__image-wrapper {
  max-width: 50%;
  text-align: right;
}

.certificate__image {
  max-width: 100%;
  height: auto;
  border-radius: 12px;
}

/* Responsive Styles - Tablet Screens */
@media (max-width: 1024px) {
  .certificate {
    flex-direction: column;
    padding: 80px 40px 60px;
    text-align: center;
  }

  .certificate__content,
  .certificate__image-wrapper {
    max-width: 100%;
    text-align: center;
  }

  .certificate__title {
    font-size: 36px;
    line-height: 48px;
    margin-bottom: 20px;
  }

  .certificate__benefit-item {
    justify-content: center;
  }

  .certificate__icon {
    width: 44px;
    height: 44px;
    margin-right: 15px;
  }

  .certificate__benefit-title {
    font-size: 18px;
  }

  .certificate__benefit-description {
    font-size: 16px;
  }

  .certificate__image-wrapper {
    margin-top: 40px;
  }
}

/* Mobile Styles - Small Screens */
@media (max-width: 768px) {
  .certificate {
    padding: 60px 20px 40px;
  }

  .certificate__title {
    font-size: 28px;
    line-height: 36px;
    margin-bottom: 15px;
  }

  .certificate__icon {
    width: 40px;
    height: 40px;
  }

  .certificate__benefit-title {
    font-size: 16px;
  }

  .certificate__benefit-description {
    font-size: 14px;
  }

  .certificate__image-wrapper {
    margin-top: 30px;
  }
}

/* Mobile Styles - Extra Small Screens */
@media (max-width: 480px) {
  .certificate {
    padding: 40px 10px 30px;
  }

  .certificate__title {
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 10px;
  }

  .certificate__icon {
    width: 36px;
    height: 36px;
  }

  .certificate__benefit-title {
    font-size: 14px;
  }

  .certificate__benefit-description {
    font-size: 13px;
  }

  .certificate__image-wrapper {
    margin-top: 20px;
  }
}
