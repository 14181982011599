.who-can-benefit {
  text-align: center;
  background-color: #fff;
  margin-top: 120px;
  padding: 0 80px;
}

.who-can-benefit__title {
  font-size: 2.5rem;
  color: #4285f3;
  margin-bottom: 2rem;
  font-weight: 700;
  letter-spacing: 1.1px;
}

/* Using CSS Grid to achieve three items per row on desktop */
.who-can-benefit__container {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Three columns for desktop */
  gap: 2rem; /* Space between items */
  justify-items: center;
}

/* Styles for individual blocks */
.who-can-benefit__block {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 350px;
  padding: 20px;
  border-radius: 12px;
  box-shadow: none;
  background-color: transparent;
  transition: box-shadow 0.3s ease-in-out, background-color 0.3s ease-in-out,
    transform 0.3s ease-in-out;
  text-align: left;
}

.who-can-benefit__block:hover {
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  transform: scale(1.03);
}

.who-can-benefit__image,
.who-can-benefit__label,
.who-can-benefit__description {
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
}

.who-can-benefit__image {
  width: 300px;
  height: 265px;
  background-size: cover;
  background-position: center;
}

.who-can-benefit__image--university {
  background-image: url("../Images/university.svg");
}

.who-can-benefit__image--students {
  background-image: url("../Images/students.svg");
}

.who-can-benefit__image--institutes {
  background-image: url("../Images/institutes.svg");
}

.who-can-benefit__label {
  font-size: 1.25rem;
  color: #142349;
  font-weight: 700;
  text-align: left;
}

.who-can-benefit__description {
  font-size: 1rem;
  color: #555;
  text-align: left;
  line-height: 1.5;
  margin-top: -5px;
}

/* Responsive Styles - Tablet Screens (2 items per row) */
@media (max-width: 1024px) {
  .who-can-benefit {
    padding: 0 40px;
    margin-top: 80px;
  }

  .who-can-benefit__title {
    font-size: 2rem;
    margin-bottom: 1.5rem;
  }

  .who-can-benefit__container {
    grid-template-columns: repeat(2, 1fr); /* Two columns for tablet */
    gap: 1.5rem;
  }

  .who-can-benefit__block {
    max-width: 300px;
    padding: 15px;
  }

  .who-can-benefit__image {
    width: 250px;
    height: 250px;
  }

  .who-can-benefit__label {
    font-size: 1.125rem;
  }

  .who-can-benefit__description {
    font-size: 0.875rem;
  }
}

/* Mobile Styles - Small Screens (1 item per row) */
@media (max-width: 768px) {
  .who-can-benefit {
    padding: 0 20px;
    margin-top: 60px;
  }

  .who-can-benefit__title {
    font-size: 1.75rem;
    margin-bottom: 1.5rem;
  }

  .who-can-benefit__container {
    grid-template-columns: 1fr; /* One column for mobile */
    gap: 1rem;
  }

  .who-can-benefit__block {
    max-width: 100%;
    padding: 10px;
  }

  .who-can-benefit__image {
    width: 100%;
    height: auto;
  }

  .who-can-benefit__label {
    font-size: 1rem;
  }

  .who-can-benefit__description {
    font-size: 0.875rem;
  }
}
