/* ContactUs.css */

.contact-us {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-top: -320px;
  margin-bottom: 100px;
}

.contact-background-page {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #f0f0f0;
}

.contact-background {
  position: relative;
  width: 100%;
  max-width: 1200px;
  text-align: center;
}

.contact-background__image {
  width: 100%;
  height: auto;
  opacity: 0.7;
}

.contact-background__title {
  position: absolute;
  top: 25%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #007bff;
  font-size: 3rem;
  font-weight: bold;
  z-index: 1;
}

.contact-us__form {
  background: #fff;
  padding: 4rem;
  border-radius: 20px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 1200px;
  box-sizing: border-box;
}

.contact-us__title {
  font-size: 2rem;
  margin-bottom: 2rem;
  text-align: center;
  color: #007bff;
}

.contact-us__row {
  display: flex;
  justify-content: space-between;
}

.contact-us__field {
  flex: 1;
  margin-bottom: 1.5rem;
  padding-right: 1rem;
}

.contact-us__field:last-child {
  padding-right: 0;
}

.contact-us__label {
  display: block;
  font-weight: 700;
  margin-bottom: 0.5rem;
  color: #000000;
  text-align: left;
  /* Ensures left alignment of the label */
}

.contact-us__input,
.contact-us__textarea {
  width: 100%;
  padding: 0.8rem;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  box-sizing: border-box;
  background-color: #f5f5f5;
}

.contact-us__textarea {
  width: 101%;
  padding: 0.8rem;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  box-sizing: border-box;
  background-color: #f5f5f5;
}

.contact-us__textarea {
  height: 120px;
  resize: none;
}

.contact-us__button {
  display: block;
  width: 100%;
  padding: 0.8rem;
  border: none;
  background-color: #4285f3;
  color: white;
  font-size: 1rem;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  font-weight: 700;
}

.contact-us__input::placeholder,
.contact-us__textarea::placeholder {
  color: #979797;
  /* Sets placeholder text color */
}

.contact-us__button:hover {
  background-color: #0056b3;
}

.contact-us__checkboxes {
  display: flex;
  flex-wrap: wrap;
  /* Wrap checkboxes if they exceed the container width */
}

.checkbox__item {
  margin-right: 20px;
  /* Add some space between the checkboxes */
  margin-bottom: 10px;
  /* Add space if they wrap to the next line */
  display: flex;
  align-items: center;
  /* Align checkbox and label vertically */
}

.checkbox__item label {
  margin-left: 8px;
  /* Space between checkbox and label */
}

@media (max-width: 768px) {
  .contact-us {
    margin-top: 80px;
    padding: 20px;
    /* Adjust margin for mobile */
  }

  .contact-us__row {
    flex-direction: column;
  }

  .contact-us__field {
    padding-right: 0;
  }

  .contact-background__title {
    font-size: 2.5rem;
    /* Smaller title on mobile */
  }

  .contact-us__form {
    padding: 1rem;
    /* Less padding for mobile */
  }

  .contact-background__image {
    margin-top: 20%;
    /* Smaller image on mobile */
  }
  .contact-background__title {
    top: 32%;
    /* Adjust title position */
  }
}