.features {
  text-align: center;

  margin-top: 120px;
  padding: 0px 80px 80px;
  background-color: #fff;
}

.features__title {
  font-size: 2.5rem;
  color: #4285f3;
  margin-bottom: 70px;
  font-weight: 700;
  letter-spacing: 1.1px;
}

.features__grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(30%, 1fr));
  gap: clamp(
    20px,
    3vw,
    40px
  ); /* gap adjusts between 20px and 40px based on screen size */
  justify-items: center;
  text-align: left;
}

.features__card {
  background-color: #fff;
  border-radius: 16px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  padding: 30px;
  width: 350px;
  transition: transform 0.3s ease-in-out;
  margin-bottom: 50px;
  border: 2px solid #e4f0fc;
}

.features__card:hover {
  transform: scale(1.05);
}

.features__icon-container {
  width: 50px;
  height: 50px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto 20px;
}

.features__icon {
  width: 30px;
  height: 30px;
}

.features__card-title {
  font-size: 1.25rem;
  color: #142349;
  font-weight: 700;
  margin-bottom: 10px;
}

.features__description {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.features__description li {
  font-size: 1rem;
  color: #555;
  margin-bottom: 8px;
  text-align: justify;
}

/* Responsive Styles - Tablet Screens */
@media (max-width: 1024px) {
  .features {
    padding: 0px 40px 60px;
  }

  .features__title {
    font-size: 2rem;
    margin-bottom: 50px;
  }

  .features__grid {
    gap: 20px;
  }

  .features__card {
    width: 100%;
    padding: 15px;
  }

  .features__card-title {
    font-size: 1.15rem;
  }

  .features__description li {
    font-size: 0.875rem;
  }
}

/* Mobile Styles - Small Screens */
@media (max-width: 768px) {
  .features {
    padding: 0px 20px 40px;
    margin-top: 100px;
  }

  .features__title {
    font-size: 1.75rem;
    margin-bottom: 40px;
  }

  .features__grid {
    grid-template-columns: 1fr;
    gap: 15px;
  }

  .features__card {
    width: 100%;
    padding: 10px;
  }

  .features__card-title {
    font-size: 1rem;
  }

  .features__description li {
    font-size: 0.85rem;
  }
}

/* Mobile Styles - Extra Small Screens */
@media (max-width: 480px) {
  .features {
    padding: 0px 30px 30px;
    margin-top: 80px;
  }

  .features__title {
    font-size: 1.5rem;
    margin-bottom: 30px;
  }

  .features__grid {
    gap: 10px;
  }

  .features__card {
    padding: 8px;
  }

  .features__card-title {
    font-size: 0.9rem;
  }

  .features__description li {
    font-size: 0.8rem;
  }
}
