/* Footer.css */
.footer {
  background-color: #fff;
  padding: 20px 80px;
  border-top: 1px solid #e0e0e0;
}

.footer__container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 20px;
  text-align: left;
}

.footer__brand-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.footer__brand {
  display: flex;
  align-items: center;
}

.footer__logo {
  width: 50px;
  height: 50px;
  margin-right: 10px;
}

.footer__brand-name {
  font-size: 24px;
  font-weight: bold;
  color: #333;
}

.footer__brand-highlight {
  color: #0066ff;
}

.footer__brand-x {
  color: #333;
}

.footer__social-media {
  display: flex;
  gap: 20px; /* Adjust spacing between icons */
  justify-content: center; /* Center icons horizontally */
  align-items: center; /* Align icons vertically */
  margin-top: 20px;
}

.footer__social-icon {
  width: 34px; /* Adjust size of the icons as needed */
  height: 34px;
  transition: transform 0.2s ease-in-out;
}

.footer__social-icon:hover {
  transform: scale(1.1); /* Slightly enlarge the icon on hover */
}

.footer__links {
  display: flex;
  gap: 100px;
}

.footer__links-section {
  display: flex;
  flex-direction: column;
}

.footer__links-title {
  color: #282b27;
  font-size: 16px;
  line-height: 19.2px;
  font-weight: bold;
  margin-bottom: 20px;
}

.footer__list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.footer__list-item {
  margin-bottom: 20px;
  color: #656b61;
  cursor: pointer;

  font-size: 16px;
  line-height: 19.2px;
}

.footer__list-item:hover {
  text-decoration: underline;
  color: #333;
}

.footer__bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #e0e0e0;
  padding-top: 10px;
}

.footer__copyright {
  color: #999;
}

.footer__policies {
  list-style: none;
  display: flex;
  gap: 20px;
  padding: 0;
  margin: 0;
}

.footer__policy {
  color: #666;
  cursor: pointer;
}

.footer__policy:hover {
  text-decoration: underline;
  color: #333;
}

/* Responsive Styles - Tablet Screens */
@media (max-width: 1024px) {
  .footer {
    padding-left: 40px;
    padding-right: 40px;
  }

  .footer__container {
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-bottom: 30px;
  }

  .footer__links {
    flex-direction: column;
    gap: 30px;
  }

  .footer__bottom {
    flex-direction: column;
    gap: 10px;
    padding-top: 20px;
  }
}

/* Mobile Styles - Small Screens */
@media (max-width: 768px) {
  .footer {
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 30px;
  }

  .footer__brand-name {
    font-size: 20px;
  }

  .footer__links {
    gap: 20px;
  }

  .footer__links-title {
    font-size: 14px;
    margin-bottom: 15px;
  }

  .footer__list-item {
    font-size: 14px;
    margin-bottom: 15px;
  }

  .footer__bottom {
    padding-top: 15px;
  }
}

/* Mobile Styles - Extra Small Screens */
@media (max-width: 480px) {
  .footer {
    padding-left: 10px;
    padding-right: 10px;
  }

  .footer__logo {
    width: 40px;
    height: 40px;
  }

  .footer__brand-name {
    font-size: 18px;
  }

  .footer__links {
    gap: 15px;
  }

  .footer__list-item {
    font-size: 13px;
    margin-bottom: 10px;
  }

  .footer__bottom {
    flex-direction: column;
    gap: 10px;
    text-align: center;
  }

  .footer__policies {
    gap: 15px;
  }
}
